import React, { useState, Suspense, createContext, Dispatch, SetStateAction } from 'react'

import { ApolloProvider } from '@apollo/client'
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import { hot } from 'react-hot-loader'
import { I18nextProvider } from 'react-i18next'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'

import ErrorState from 'components/ErrorState'
import Loader from 'components/lib/Loader'
import withErrorHandler from 'components/lib/withErrorHandler'
import OpenLinkSurveyContainer from 'components/OpenLinkSurveyContainer'
import PortalScreen from 'components/PortalScreen'
import PreviewContainer from 'components/PreviewContainer'
import SurveyContainer from 'components/SurveyContainer'
import { FINGERPRINT_API_KEY, FINGERPRINT_ENDPOINT } from 'config/'
import apolloClient from 'config/apollo'
import ReactGA from 'config/googleAnalytics'
import theme from 'config/theme'
import { usePublicConstantsQuery } from 'generated/graphql'
import { useTranslations, generateI18n, LanguageContext } from 'locales'
import { URLS } from 'utils/constants'

import './App.css'

const App: React.FC = () => {
  const [languageCode, setLanguageCode] = useState('en')

  const { i18n } = useTranslations({ useSuspense: false })

  const changeLanguageCode = (newLanguageCode: string) => {
    i18n.changeLanguage(newLanguageCode)
    setLanguageCode(newLanguageCode)
  }

  return (
    <LanguageContext.Provider value={{ languageCode, changeLanguageCode }}>
      <Route
        path="/"
        render={({ location }) => {
          ReactGA?.pageview(location.pathname + location.search)
          return null
        }}
      />

      <Switch>
        <Route path={`/${URLS.PREVIEW}/:surveyId/:source`} component={PreviewContainer} />

        {/* TODO: AI-2269 Remove the "?" (optional param) */}
        <Route
          path={`/${URLS.SURVEY}/:participantId/:sourceFromUrl?`}
          component={SurveyContainer}
        />
        <Route
          path={`/${URLS.DEPRECATED_SURVEY}/:participantId/:sourceFromUrl?`}
          component={SurveyContainer}
        />

        <Route
          path={`/${URLS.OPEN_LINK_SURVEY}/:surveyHashId`}
          component={OpenLinkSurveyContainer}
        />
        <Route
          path={`/${URLS.OPEN_LINK_SURVEY_PREVIEW}/:surveyHashId`}
          component={() => <OpenLinkSurveyContainer isPreview />}
        />
        <Redirect
          from={`/${URLS.TAKE_SURVEY}/:participantId`}
          to={`/${URLS.SURVEY}/:participantId`}
        />
        <Route path="/:slug" component={PortalScreen} />
        <Redirect to="/no-org" />
      </Switch>
    </LanguageContext.Provider>
  )
}

const AppWrapper: React.FC = () => {
  const result = usePublicConstantsQuery()

  if (result.loading) {
    return <Loader />
  }

  if (!result.data) {
    return <ErrorState message="Error" />
  }

  return (
    <I18nextProvider i18n={generateI18n(result.data?.publicConstants.languages.map(l => l.code))}>
      <App />
    </I18nextProvider>
  )
}

// When a participant has duplicated contact info, we show a screen allowing the user to choose which participant they are.
// This context is used to store the selected participant id.
const duplicateParticipantDefaults: {
  duplicateParticipantSelectionId: string | null
  setDuplicateParticipantSelectionId: Dispatch<SetStateAction<string | null>>
} = {
  duplicateParticipantSelectionId: null,
  setDuplicateParticipantSelectionId: () => {},
}
export const DuplicateParticipantSelectionContext = createContext(duplicateParticipantDefaults)

const AppContainer: React.FC = () => {
  const [duplicateParticipantSelectionId, setDuplicateParticipantSelectionId] = useState<
    string | null
  >(null)

  return (
    <DuplicateParticipantSelectionContext.Provider
      value={{ duplicateParticipantSelectionId, setDuplicateParticipantSelectionId }}
    >
      <BrowserRouter>
        <ApolloProvider client={apolloClient}>
          <FpjsProvider
            loadOptions={{
              apiKey: FINGERPRINT_API_KEY,
              endpoint: FINGERPRINT_ENDPOINT,
            }}
          >
            <Suspense fallback="">
              <MuiThemeProvider theme={theme}>
                <CssBaseline>
                  <AppWrapper />
                </CssBaseline>
              </MuiThemeProvider>
            </Suspense>
          </FpjsProvider>
        </ApolloProvider>
      </BrowserRouter>
    </DuplicateParticipantSelectionContext.Provider>
  )
}

export default hot(module)(withErrorHandler(AppContainer))

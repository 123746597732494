import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import cn from 'classnames'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { ReactComponent as HeadsetIcon } from 'assets/img/headset.svg'
import AlertModal from 'components/lib/AlertModal'
import useGlobalStyles from 'components/lib/GlobalStyles'
import NavBar from 'components/lib/NavBar'
import NextButton from 'components/lib/NextButton'
import {
  saveResponseToLocalStorage,
  useOfflineResponseHandler,
} from 'components/lib/OfflineResponseHandler'
import LinearBlock from 'components/Survey/Blocks/LinearBlock'
import MultipleChoiceBlock from 'components/Survey/Blocks/MultipleChoiceBlock'
import ShortAnswerBlock from 'components/Survey/Blocks/ShortAnswerBlock'
import TransitionBlock from 'components/Survey/Blocks/TransitionBlock'
import {
  populateCustomTransitions,
  populateEngagementTransitions,
  populateMoveOutOrDischargeTransitions,
  populateNonConfidentialTransition,
  populateOpenLinkTransition,
  populatePulseTransitions,
  Transition,
} from 'components/Survey/Transition'
import ReactGA from 'config/googleAnalytics'
import {
  BenchmarkCodeType,
  LanguageType,
  QKind,
  SurveyByParticipantIdDocument,
  SurveyByParticipantIdQuery,
  SurveyTypeEnum,
  useUpdateResidentParticipantMutation,
  ParticipantSourceTypeInput,
  SurveyProductTypeEnum,
  PublicConstantsQuery,
} from 'generated/graphql'
import { LanguageContext, TranslationKey, useTranslations } from 'locales'
import {
  desktopStyle,
  getCompletionPercentageWithinRange,
  getUrlPrefix,
  renderTemplate,
} from 'utils'
import { SurveyDisplayStrategy, URLS } from 'utils/constants'
import { LOCTypeEnum } from 'utils/generatedFrontendConstants'

export type TranslatedQuestions = NonNullable<
  NonNullable<SurveyByParticipantIdQuery['surveyByParticipantId']>['participant']['questions']
>
export type TranslatedQuestion = TranslatedQuestions[0]
// We are manually typing the Question so that typescript sees the question text as a key that needs translation. After we translate the question,
// we are using the regular typing as received from the backend, where the text is a string.
export type PublicQuestion = Omit<TranslatedQuestions[0], 'text'> & { text: TranslationKey }
type Responses = { [key: string]: string }
export type PublicQuestions = Array<PublicQuestion>
export type TranslatedTransition = { text: string; buttonLabel?: string }

export const getQuestionChoices = (question: TranslatedQuestion) => {
  if (question.kind === QKind.STATEMENT) {
    const choices: ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'] = [
      'Strongly Disagree',
      'Disagree',
      'Neutral',
      'Agree',
      'Strongly Agree',
    ]
    return choices.map((text, index) => ({
      text,
      code: String(index + 1),
    }))
  }
  if (question.kind === QKind.STATEMENT_EXCELLENCE) {
    const choices: ['Poor', 'Average', 'Good', 'Very Good', 'Excellent'] = [
      'Poor',
      'Average',
      'Good',
      'Very Good',
      'Excellent',
    ]
    return choices.map((text, index) => ({
      text,
      code: String(index + 1),
    }))
  }
  if (question.choices) {
    return question.choices.map(({ text, code, benchmarkCode }) => ({
      text: (text || '') as TranslationKey,
      code: code || '',
      benchmarkCode: benchmarkCode || '',
    }))
  }
  return []
}
type QProps = {
  question: TranslatedQuestion
  goNext?: () => void
  response?: string
  onChange: (response: string) => void
}

export const QuestionBlock: React.FC<QProps> = props => {
  const { question } = props
  switch (question.kind) {
    case QKind.SHORT_ANSWER:
    case QKind.OPEN_ENDED: {
      let subtext: undefined | TranslationKey
      if (question.benchmarkCode === BenchmarkCodeType.TESTIMONIALS_REVIEW) {
        subtext =
          'Note: By leaving this comment you consent to it being shared with other parties and posted on other websites.'
      }
      return (
        <ShortAnswerBlock
          multiline={question.kind === QKind.OPEN_ENDED}
          subtext={subtext}
          {...props}
        />
      )
    }
    case QKind.STATEMENT:
    case QKind.STATEMENT_EXCELLENCE:
    case QKind.MULTISELECT:
    case QKind.MULTIPLE_CHOICE: {
      return (
        <MultipleChoiceBlock
          choices={getQuestionChoices(question)}
          isMultiselect={question.kind === QKind.MULTISELECT}
          {...props}
          displayHorizontal={
            question.kind !== QKind.MULTIPLE_CHOICE && question.kind !== QKind.MULTISELECT
          }
        />
      )
    }
    case QKind.LINEAR: {
      return <LinearBlock {...props} />
    }
    default:
      return null
  }
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      position: 'fixed',
      width: '100%',
      height: '10%',
      bottom: 0,
      backgroundColor: theme.palette.common.fadedGrey,
      display: 'flex',
      alignItems: 'center',
      ...desktopStyle({
        justifyContent: 'center',
      }),
    },
    progressBlock: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '5%',
      marginRight: '5%',
      width: '100%',
      ...desktopStyle({
        width: '40%',
      }),
    },
    questionBatchBlock: {
      maxHeight: '90vh',
      overflow: 'scroll',
    },
    questionBatchProgressBlock: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '5%',
      paddingRight: '5%',
      paddingTop: 80,
      width: '100%',
      ...desktopStyle({
        paddingLeft: '25%',
        paddingRight: '25%',
      }),
    },
    progressBar: {
      width: '100%',
      marginRight: theme.spacing(1),
    },
    progressBarLineColor: {
      backgroundColor: theme.palette.common.brandBlue,
    },
    progressBarColor: {
      height: 10,
      borderRadius: 6,
      // Brand blue with 0.35 opacity hex code
      backgroundColor: '#346EF059',
    },
    instructionText: {
      display: 'flex',
      alignItems: 'top',
      backgroundColor: '#EDF7ED',
      padding: 10,
      marginBottom: 20,
      borderRadius: 5,
      '& >svg': {
        width: 40,
        height: 30,
        marginRight: 10,
      },
    },
  }),
)

export const getStartIndex = (
  rawQuestions: PublicQuestions,
  translatedQuestions: Array<TranslatedQuestion | Transition>,
  responses: Responses,
  source?: ParticipantSourceTypeInput,
) => {
  // Phone surveys always start from the first question
  if (source === ParticipantSourceTypeInput.P) {
    return 0
  }
  // Find the first question they haven't answered in the raw questions (excluding transitions)
  let foundIndex = rawQuestions?.findIndex(q => !responses[q.code])
  if (foundIndex === 0) {
    // Start at the beginning
    return 0
  }
  if (foundIndex === -1) {
    // They answered every question, use the last question
    foundIndex = rawQuestions.length - 1
  }
  // Find that question index in the full question list that includes transitions
  return translatedQuestions.findIndex(q => q.text === rawQuestions[foundIndex].text)
}

export const getVisibleRawQuestions = (questions: PublicQuestions, responses: Responses) => {
  // Some questions should only be displayed if certain choices were selected for the previous questions
  const hiddenConditionalQuestions = new Set()
  questions.forEach(question => {
    // Check if this question shoud be hidden by checking if any of the choices it depends on is among the responses
    if (
      question.dependsOnChoices?.length &&
      // and if the question depends on choices that haven't been picked/chosen
      !question.dependsOnChoices.some(choice => responses[choice.question.code] === choice.code)
    ) {
      hiddenConditionalQuestions.add(question.code)
    }
  })
  return questions.filter(q => q.visible && !hiddenConditionalQuestions.has(q.code))
}

export const getTextInstructionByCode = (
  questions: PublicQuestion[],
  questionInstructions: PublicConstantsQuery['publicConstants']['questionInstructions'],
  source?: ParticipantSourceTypeInput,
) => {
  // This object is a questionCode => instructionText mapping
  // If a question given by code is in this object, it means that the user should be shown the instruction text before the question
  const textInstructionByCode: { [key: string]: string | undefined } = {}
  // Instructions should only be visible for phone surveys, agents will read the instructions to the participant
  if (source !== ParticipantSourceTypeInput.P) {
    return textInstructionByCode
  }

  for (let i = 0; i < questions.length; i += 1) {
    // Before first question, we always need to show instructions
    if (i === 0 || questions[i - 1].kind !== questions[i].kind) {
      // Only display instructions if kind changes
      textInstructionByCode[questions[i].code] = questionInstructions.find(
        instr => instr.kind === questions[i].kind,
      )?.text
    }

    // Override with specific question isntructions if they exist
    const instruction = questionInstructions.find(
      instr =>
        questions[i].benchmarkCode && instr.question?.benchmarkCode === questions[i].benchmarkCode,
    )
    if (instruction) {
      textInstructionByCode[questions[i].code] = instruction.text
    }
  }

  return textInstructionByCode
}

type Props = {
  questions: PublicQuestions
  templates: { [key: string]: string }
  participantId?: string
  responses: Responses
  updateResponses?(response: string, code: string): void // For updating preview responses
  levelOfCare: LOCTypeEnum
  surveyType: SurveyTypeEnum
  surveyProductType: SurveyProductTypeEnum
  isOpenLinkSurvey: boolean
  surveyDisplayStrategy: SurveyDisplayStrategy
  questionBatchSize: number
  hasConfidentialResults: boolean
  languages: LanguageType[]
  source?: ParticipantSourceTypeInput
  questionInstructions: PublicConstantsQuery['publicConstants']['questionInstructions']
}

const Survey: React.FC<Props> = ({
  questions: inputQuestions,
  responses,
  updateResponses,
  templates,
  participantId,
  levelOfCare,
  surveyType,
  surveyProductType,
  isOpenLinkSurvey,
  surveyDisplayStrategy,
  questionBatchSize,
  hasConfidentialResults,
  languages,
  source,
  questionInstructions,
}) => {
  const classes = { ...useStyles(), ...useGlobalStyles() }
  const { t } = useTranslations()
  const { url } = useRouteMatch()
  const history = useHistory()
  const [isDisconnected] = useOfflineResponseHandler(participantId, source)
  // This constant tells if the survey is showing on a single big page
  const isSinglePageSurvey = questionBatchSize === Number.MAX_VALUE
  const [disconnectedAlertMessage, setDisconnectedAlertMessage] = useState<string | null>(null)
  const { data: visitorData } = useVisitorData()
  const fingerprint = visitorData?.visitorId || ''
  // Use a ref so the closure in the setTimeout below has access to the most current value.
  const isDisconnectedRef = useRef(isDisconnected)
  isDisconnectedRef.current = isDisconnected
  // If the user goes offline, check if they are still offline after 30 seconds and show them an alert.
  useEffect(() => {
    if (isDisconnected) {
      setTimeout(() => {
        if (isDisconnectedRef.current) {
          setDisconnectedAlertMessage(
            t(
              'Please ensure you have a valid connection, otherwise some responses may not be recorded.',
            ),
          )
        }
      }, 30000)
    }
  }, [isDisconnected, t])

  const rawQuestions = getVisibleRawQuestions(inputQuestions, responses)
  // Need to calculate textInstructionByCode on the raw questions, before we populate with the templates
  const textInstructionByCode = getTextInstructionByCode(rawQuestions, questionInstructions, source)
  let questionsList: Array<PublicQuestion | Transition> = []

  // Only apply transitions to resident surveys
  if (surveyProductType === SurveyProductTypeEnum.RESIDENT) {
    if (surveyType === SurveyTypeEnum.RESIDENT_CUSTOM) {
      questionsList = populateCustomTransitions(rawQuestions)
    } else if (
      [SurveyTypeEnum.RESIDENT_END_OF_SERVICE, SurveyTypeEnum.RESIDENT_DISCHARGE].includes(
        surveyType,
      )
    ) {
      questionsList = populateMoveOutOrDischargeTransitions(rawQuestions, levelOfCare)
    } else if (surveyType === SurveyTypeEnum.RESIDENT_PULSE) {
      questionsList = populatePulseTransitions(rawQuestions, hasConfidentialResults)
    } else {
      questionsList = populateEngagementTransitions(
        rawQuestions,
        levelOfCare,
        hasConfidentialResults,
      )
    }
    if (!hasConfidentialResults) {
      questionsList = populateNonConfidentialTransition(questionsList)
    }
    if (isOpenLinkSurvey) {
      questionsList = populateOpenLinkTransition(questionsList)
    }
  } else {
    questionsList.push(...rawQuestions)
  }

  const [questionIndex, setQuestionIndex] = useState(
    // Find the question index before the templates are rendered.
    getStartIndex(rawQuestions, questionsList, responses, source),
  )

  // Populate the question text templates and run them through translations.
  const translatedQuestions = questionsList.map(questionOrTransition => {
    if (questionOrTransition instanceof Transition) {
      // We can't use object destructing here because it will lose the property of
      // `questionOrTransition` as an instance of Transition.
      // eslint-disable-next-line
      ;(questionOrTransition as TranslatedTransition).text = renderTemplate(
        t(questionOrTransition.text),
        templates,
      )
      return questionOrTransition
    }
    return {
      ...questionOrTransition,
      text: renderTemplate(t(questionOrTransition.text), templates),
    }
  })
  const [updateParticipant] = useUpdateResidentParticipantMutation()
  // Open link surveys start from 15% because the first section of the survey, the personal info questions section, is hard-coded to represent a 15% progress
  const startRange = isOpenLinkSurvey ? 15 : 0
  let completionPercentage = getCompletionPercentageWithinRange(
    questionIndex,
    translatedQuestions.length,
    startRange,
    100,
  )
  const question = translatedQuestions[questionIndex]
  const isFirstQuestion = questionIndex === 0
  const isLastQuestion = questionIndex === translatedQuestions.length - 1
  // In open link surveys we don't want to let them go back to the personal info questions section
  const hideGoBack = isOpenLinkSurvey && isFirstQuestion
  const submitSurvey = () => {
    if (isDisconnected) {
      setDisconnectedAlertMessage(
        t(
          'Please ensure you have a valid connection before pressing submit, otherwise some responses may not be recorded.',
        ),
      )
      return
    }
    // Don't update on the preview
    if (participantId) {
      updateParticipant({
        variables: {
          participantId,
          submitted: true,
          fingerprint,
          source,
        },
      })
    }
    history.replace(`${getUrlPrefix(url)}/${URLS.COMPLETE}`)
  }
  const goNext = () => {
    if (isLastQuestion) {
      submitSurvey()
      return
    }
    const nextQuestionIndex = questionIndex + 1
    const nextQuestion = translatedQuestions[nextQuestionIndex]
    if (ReactGA) {
      if (nextQuestion instanceof Transition) {
        ReactGA.event({
          action: 'viewTransition',
          category: 'SurveyTaking',
          label: nextQuestion.text,
        })
      } else {
        ReactGA.event({
          action: 'viewQuestion',
          category: 'SurveyTaking',
          label: nextQuestion.benchmarkCode || '',
        })
      }
    }
    setQuestionIndex(nextQuestionIndex)
  }
  const goBack = () => {
    if (isFirstQuestion) {
      history.push(`${getUrlPrefix(url)}/${URLS.WELCOME}`)
      return
    }
    setQuestionIndex(questionIndex - 1)
  }
  const onResponseChange = (responseVal: string, code: string) => {
    // We don't need to worry about saving offline responses in preview mode
    if (participantId) {
      saveResponseToLocalStorage(responseVal, code, participantId)
    }
    // Manually update responses on the preview
    if (!participantId) {
      if (updateResponses) {
        updateResponses(responseVal, code)
      }
      return
    }
    if (ReactGA) {
      ReactGA.event({
        action: 'answerQuestion',
        category: 'SurveyTaking',
        label: `${code} — ${responseVal}`,
      })
    }
    const variables = {
      participantId,
      questionCode: code,
      response: responseVal,
      submitted: false,
      fingerprint,
      timestamp: new Date(),
      source,
    }
    updateParticipant({
      variables,
      // Use optimistic UI so the user gets instant feedback without needing to keep state
      optimisticResponse: {
        __typename: 'Mutation',
        updateResidentParticipant: {
          __typename: 'UpdateResidentParticipant',
          errors: null,
          response: { code, response: responseVal, __typename: 'PublicResponse' },
        },
      },
      // Manually update the cache so we can incorporate the optimistic UI response
      update: (proxy, { data: mutationData }) => {
        const vars = { participantId, source }
        const data = proxy.readQuery<SurveyByParticipantIdQuery>({
          query: SurveyByParticipantIdDocument,
          variables: vars,
        })
        if (!data?.surveyByParticipantId?.participant) {
          return
        }

        let updatedResponses = data.surveyByParticipantId.participant.responses || []
        // Remove the response if it already exists in the array so we can update it
        updatedResponses = updatedResponses.filter(r => r.code !== code)
        proxy.writeQuery({
          query: SurveyByParticipantIdDocument,
          variables: vars,
          data: {
            ...data,
            surveyByParticipantId: {
              ...data.surveyByParticipantId,
              participant: {
                ...data.surveyByParticipantId.participant,
                responses: [...updatedResponses, mutationData?.updateResidentParticipant?.response],
              },
            },
          },
        })
      },
    })
  }
  let screenBlock: ReactElement | null = null
  if (question instanceof Transition) {
    screenBlock = (
      <TransitionBlock label={question.text} goNext={goNext} nextLabel={question.buttonLabel} />
    )
  } else if (
    question.benchmarkCode === BenchmarkCodeType.TESTIMONIALS_APPROVAL ||
    question.benchmarkCode === BenchmarkCodeType.TESTIMONIALS_PRIVACY
  ) {
    const [yesOption, noOption] = getQuestionChoices(question)
    screenBlock = (
      <TransitionBlock
        questionId={question.benchmarkCode}
        label={question.text}
        nextLabel={yesOption.text as TranslationKey}
        goNext={() => {
          onResponseChange(yesOption.code, question.code)
          goNext()
        }}
        skipLabel={noOption.text as TranslationKey}
        onSkip={() => {
          onResponseChange(noOption.code, question.code)
          goNext()
        }}
      />
    )
  } else {
    screenBlock = (
      <>
        <div className={classes.questionBlock}>
          {textInstructionByCode[question.code] && (
            <div className={classes.instructionText}>
              <HeadsetIcon />
              <div>{textInstructionByCode[question.code]}</div>
            </div>
          )}
          <QuestionBlock
            question={question}
            goNext={goNext}
            response={responses[question.code]}
            onChange={(r: string) => onResponseChange(r, question.code)}
          />
          <NextButton
            nextLabel={isLastQuestion ? 'Submit' : 'Next'}
            goNext={goNext}
            color="primary"
          />
        </div>
        {!isSinglePageSurvey && (
          <div className={classes.footer}>
            <div className={classes.progressBlock}>
              <LinearProgress
                className={classes.progressBar}
                classes={{
                  colorPrimary: classes.progressBarColor,
                  barColorPrimary: classes.progressBarLineColor,
                }}
                color="primary"
                variant="determinate"
                value={completionPercentage}
              />
              <Typography variant="caption">{completionPercentage}%</Typography>
            </div>
          </div>
        )}
      </>
    )
  }
  // Single page survey ignores transitions.
  const translatedQuestionsWithoutTransitions = translatedQuestions.filter(
    (q): q is TranslatedQuestion => !(q instanceof Transition),
  )
  const useQuestionBatch = surveyDisplayStrategy === SurveyDisplayStrategy.QUESTION_BATCH
  if (useQuestionBatch) {
    completionPercentage = getCompletionPercentageWithinRange(
      questionIndex,
      translatedQuestionsWithoutTransitions.length,
      0,
      100,
    )
  }
  const questionBatchBlockRef = useRef<HTMLDivElement>(null)
  // Default next button for a question batch moves the survey along to the next batch.
  let questionBatchNextButton = (
    <NextButton
      nextLabel="Next"
      goNext={() => {
        setQuestionIndex(questionIndex + questionBatchSize)
        if (questionBatchBlockRef?.current) {
          questionBatchBlockRef.current.scroll({
            top: 0,
          })
        }
      }}
      color="primary"
    />
  )
  // If we're at the end of the survey, change the next button to submit.
  if (questionIndex + questionBatchSize >= translatedQuestionsWithoutTransitions.length) {
    questionBatchNextButton = (
      <NextButton nextLabel="Submit" goNext={submitSurvey} color="primary" />
    )
  }
  return (
    <div>
      <LanguageContext.Consumer>
        {() => (
          <>
            <NavBar goBack={goBack} hideGoBack={hideGoBack} languages={languages} />
            {useQuestionBatch ? (
              <>
                {!isSinglePageSurvey && (
                  <div className={classes.questionBatchProgressBlock}>
                    <LinearProgress
                      className={classes.progressBar}
                      classes={{
                        colorPrimary: classes.progressBarColor,
                        barColorPrimary: classes.progressBarLineColor,
                      }}
                      color="primary"
                      variant="determinate"
                      value={completionPercentage}
                    />
                    <Typography variant="caption">{completionPercentage}%</Typography>
                  </div>
                )}
                <div className={classes.questionBatchBlock} ref={questionBatchBlockRef}>
                  {translatedQuestionsWithoutTransitions
                    .slice(questionIndex, questionIndex + questionBatchSize)
                    .map(q => {
                      return (
                        <div
                          key={q.code}
                          className={cn({
                            [classes.questionBlock]: true,
                            [classes.singlePageQuestionBlock]: useQuestionBatch,
                          })}
                        >
                          {textInstructionByCode[q.code] && (
                            <div className={classes.instructionText}>
                              <HeadsetIcon />
                              <div>{textInstructionByCode[q.code]}</div>
                            </div>
                          )}
                          <QuestionBlock
                            question={q}
                            response={responses[q.code]}
                            onChange={(r: string) => onResponseChange(r, q.code)}
                          />
                        </div>
                      )
                    })}
                  <div className={classes.questionBlock}>
                    {questionBatchNextButton}
                    <br />
                    <br />
                  </div>
                </div>
              </>
            ) : (
              <div className={classes.screenContainer}>{screenBlock}</div>
            )}
            {disconnectedAlertMessage && (
              <AlertModal
                title={t('You are currently disconnected from the internet.')}
                description={disconnectedAlertMessage}
                onClose={() => setDisconnectedAlertMessage(null)}
              />
            )}
          </>
        )}
      </LanguageContext.Consumer>
    </div>
  )
}

export default Survey

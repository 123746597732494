import { PublicQuestion, PublicQuestions } from 'components/Survey/Survey'
import { BenchmarkCodeType, QResidentFocus } from 'generated/graphql'
import { TranslationKey } from 'locales'
import { LOCTypeEnum } from 'utils/generatedFrontendConstants'

export class Transition {
  text: TranslationKey

  buttonLabel?: TranslationKey

  constructor(text: TranslationKey, buttonLabel?: TranslationKey) {
    this.text = text
    this.buttonLabel = buttonLabel
  }
}
// Insert transition screens into the "questions" list, so we can use them in order.
// These rules are very ad-hoc so it would be difficult to use in a configurable way.
export const populateEngagementTransitions = (
  questions: PublicQuestion[],
  levelOfCare: LOCTypeEnum,
  hasConfidentialResults: boolean,
) => {
  const questionsWithTransitions: Array<PublicQuestion | Transition> = []
  let currentFocus: PublicQuestion['residentFocus'] = QResidentFocus.OVERALL_SATISFACTION

  // Before the survey
  if (hasConfidentialResults) {
    questionsWithTransitions.push(
      new Transition(
        `Your responses will be collected by a 3rd party, Activated Insights, to ensure confidentiality.`,
      ),
    )
  } else {
    questionsWithTransitions.push(
      new Transition(`Your responses will be collected by a 3rd party, Activated Insights.`),
    )
  }

  questionsWithTransitions.push(
    new Transition(
      `Please note that if you choose to use your name or others’ names in your responses to open-ended questions, they will appear verbatim and staff at {{location_name}} may see them.`,
      `Let’s get started!`,
    ),
  )

  if (![LOCTypeEnum.AFFORDABLE_HOUSING, LOCTypeEnum.INDEPENDENT_LIVING].includes(levelOfCare)) {
    questionsWithTransitions.push(
      new Transition(
        `The following are part of a national initiative to measure the quality of skilled nursing care centers/assisted living communities.`,
      ),
    )
  }
  questions.forEach(question => {
    // After overall satisfaction question
    if (
      currentFocus === QResidentFocus.OVERALL_SATISFACTION &&
      question.residentFocus === QResidentFocus.SERVICE_AREAS
    ) {
      questionsWithTransitions.push(
        new Transition(
          `Next, we’d like to understand your experiences with specific areas of the community.`,
        ),
      )
      questionsWithTransitions.push(
        new Transition(
          `If any questions don’t apply to you or you don’t want to answer them, you can skip by clicking next.`,
        ),
      )
    } // Before demographics questions
    else if (
      question.residentFocus === QResidentFocus.DEMOGRAPHICS &&
      currentFocus !== QResidentFocus.DEMOGRAPHICS
    ) {
      questionsWithTransitions.push(
        new Transition(
          `Okay! Next, we’d like to get to know you better so we can help {{location_name}} better understand how to meet ALL residents’ needs.`,
        ),
      )
    } // Before open-ended questions
    else if (
      question.residentFocus === QResidentFocus.OPEN_ENDED &&
      currentFocus !== QResidentFocus.OPEN_ENDED
    ) {
      questionsWithTransitions.push(
        new Transition(`Next, we have a few questions about your experience overall.`),
      )
    } // Before Companionship question
    else if (question.benchmarkCode === BenchmarkCodeType.COMPANIONSHIP) {
      questionsWithTransitions.push(
        new Transition(`We’d like to know more about how you’re feeling in general...`),
      )
    } // Before Assistance question
    else if (question.benchmarkCode === BenchmarkCodeType.ASSISTANCE) {
      questionsWithTransitions.push(
        new Transition(`You are almost done! Just a few more questions.`),
      )
    }
    currentFocus = question.residentFocus
    questionsWithTransitions.push(question)
  })
  // After last question
  questionsWithTransitions.push(
    new Transition(
      `You’re done! Go back to change any answer, or click below to submit your survey!`,
      'Submit',
    ),
  )
  return questionsWithTransitions
}

export const populatePulseTransitions = (
  questions: PublicQuestion[],
  hasConfidentialResults: boolean,
) => {
  const questionsWithTransitions: Array<PublicQuestion | Transition> = []
  let currentFocus: PublicQuestion['residentFocus'] = QResidentFocus.OVERALL_SATISFACTION
  // Before the survey
  if (hasConfidentialResults) {
    questionsWithTransitions.push(
      new Transition(
        `Your responses will be collected by a 3rd party, Activated Insights, to ensure confidentiality.`,
      ),
    )
  } else {
    questionsWithTransitions.push(
      new Transition(`Your responses will be collected by a 3rd party, Activated Insights.`),
    )
  }

  questionsWithTransitions.push(
    new Transition(
      `Please note that if you choose to use your name or others’ names in your responses to open-ended questions, they will appear verbatim and staff at {{location_name}} may see them.`,
      `Continue`,
    ),
  )
  questionsWithTransitions.push(
    new Transition(
      `If any questions don’t apply to you or you don’t want to answer them, you can skip by clicking next.`,
      `Let’s get started!`,
    ),
  )
  questions.forEach(question => {
    // Before demographics questions
    if (
      question.residentFocus === QResidentFocus.DEMOGRAPHICS &&
      currentFocus !== QResidentFocus.DEMOGRAPHICS
    ) {
      questionsWithTransitions.push(
        new Transition(
          `Okay! Next, we’d like to get to know you better so we can help {{location_name}} better understand how to meet ALL residents’ needs.`,
        ),
      )
    }
    // Before Testimonials question
    else if (question.benchmarkCode === BenchmarkCodeType.TESTIMONIALS_APPROVAL) {
      questionsWithTransitions.push(
        new Transition(`You are almost done! Just a few more questions.`),
      )
    }
    currentFocus = question.residentFocus
    questionsWithTransitions.push(question)
  })
  // After last question
  questionsWithTransitions.push(
    new Transition(
      `You’re done! Go back to change any answer, or click below to submit your survey!`,
      'Submit',
    ),
  )
  return questionsWithTransitions
}

export const populateMoveOutOrDischargeTransitions = (
  questions: PublicQuestion[],
  levelOfCare: LOCTypeEnum,
) => {
  const questionsWithTransitions: Array<PublicQuestion | Transition> = []
  let currentFocus: PublicQuestion['residentFocus'] = QResidentFocus.OVERALL_SATISFACTION
  // Before the survey
  questionsWithTransitions.push(
    new Transition(
      `Your responses will be collected by a 3rd party, Activated Insights. Let’s get started!`,
    ),
  )

  if (
    ![LOCTypeEnum.AFFORDABLE_HOUSING, LOCTypeEnum.INDEPENDENT_LIVING].includes(levelOfCare) &&
    !questions.find(q => q.benchmarkCode === BenchmarkCodeType.NPS_RECOMMEND)
  ) {
    questionsWithTransitions.push(
      new Transition(
        `The following are part of a national initiative to measure the quality of skilled nursing care centers/assisted living communities.`,
      ),
    )
  }
  questions.forEach(question => {
    // After overall satisfaction question
    if (
      currentFocus === QResidentFocus.OVERALL_SATISFACTION &&
      question.residentFocus === QResidentFocus.SERVICE_AREAS
    ) {
      questionsWithTransitions.push(
        new Transition(
          `Next, we’d like to understand your experiences with specific areas of the community.`,
        ),
      )
      questionsWithTransitions.push(
        new Transition(
          `If any questions don’t apply to you or you don’t want to answer them, you can skip by clicking next.`,
        ),
      )
    } // Before demographics questions
    else if (
      currentFocus !== QResidentFocus.DEMOGRAPHICS &&
      question.residentFocus === QResidentFocus.DEMOGRAPHICS &&
      ![BenchmarkCodeType.MEDICAL_NEEDS, BenchmarkCodeType.LEFT_REASON].includes(
        question.benchmarkCode as BenchmarkCodeType,
      )
    ) {
      questionsWithTransitions.push(
        new Transition(
          `Okay! Next, we’d like to get to know you better so we can help {{location_name}} better understand how to meet everyone’s needs.`,
        ),
      )
    } // Before reason for leaving and open-ended questions
    else if (question.benchmarkCode === BenchmarkCodeType.LEFT_REASON) {
      questionsWithTransitions.push(
        new Transition(`Next, we have a few questions about your experience overall.`),
      )
    } // Before the Assistance question for clients and Distance question for representatives
    else if (
      [BenchmarkCodeType.ASSISTANCE, BenchmarkCodeType.DISTANCE].includes(
        question.benchmarkCode as BenchmarkCodeType,
      )
    ) {
      questionsWithTransitions.push(
        new Transition(`You are almost done! Just a few more questions.`),
      )
    }
    currentFocus = question.residentFocus
    questionsWithTransitions.push(question)
  })
  // After last question
  questionsWithTransitions.push(
    new Transition(
      `You’re done! Go back to change any answer, or click below to submit your survey!`,
      'Submit',
    ),
  )
  return questionsWithTransitions
}

export const populateCustomTransitions = (questions: PublicQuestions) => {
  return [
    ...questions,
    new Transition(
      `You’re done! Go back to change any answer, or click below to submit your survey!`,
      'Submit',
    ),
  ]
}

export const populateNonConfidentialTransition = (
  questions: Array<PublicQuestion | Transition>,
) => {
  return [
    new Transition(
      'Please note, this survey isn’t anonymous. Staff at {{location_name}} will see that your feedback is from you.',
    ),
    ...questions,
  ]
}

export const populateOpenLinkTransition = (questions: Array<PublicQuestion | Transition>) => {
  return [
    new Transition(
      'Thank you! Next, we’d like to understand your overall experience at {{location_name}}.',
    ),
    ...questions,
  ]
}
